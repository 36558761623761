<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_968_65039"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_968_65039)"
      />
    </mask>
    <g mask="url(#mask0_968_65039)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_968_65039)"
      />
      <g filter="url(#filter0_d_968_65039)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_968_65039)"
        />
      </g>
      <path
        d="M3.23242 11.7344L4.32812 14.8281L5.41797 11.7344H6.57227V16H5.69043V14.834L5.77832 12.8213L4.62695 16H4.02344L2.875 12.8242L2.96289 14.834V16H2.08398V11.7344H3.23242ZM8.21875 14.4971V16H7.33984V11.7344H9.00391C9.32422 11.7344 9.60547 11.793 9.84766 11.9102C10.0918 12.0273 10.2793 12.1943 10.4102 12.4111C10.541 12.626 10.6064 12.8711 10.6064 13.1465C10.6064 13.5645 10.4629 13.8945 10.1758 14.1367C9.89062 14.377 9.49512 14.4971 8.98926 14.4971H8.21875ZM8.21875 13.7852H9.00391C9.23633 13.7852 9.41309 13.7305 9.53418 13.6211C9.65723 13.5117 9.71875 13.3555 9.71875 13.1523C9.71875 12.9434 9.65723 12.7744 9.53418 12.6455C9.41113 12.5166 9.24121 12.4502 9.02441 12.4463H8.21875V13.7852ZM13.7793 14.1514H12.0918V15.2939H14.0723V16H11.2129V11.7344H14.0664V12.4463H12.0918V13.4629H13.7793V14.1514ZM17.9629 15.4609C17.8047 15.6504 17.5811 15.7979 17.292 15.9033C17.0029 16.0068 16.6826 16.0586 16.3311 16.0586C15.9619 16.0586 15.6377 15.9785 15.3584 15.8184C15.0811 15.6562 14.8662 15.4219 14.7139 15.1152C14.5635 14.8086 14.4863 14.4482 14.4824 14.0342V13.7441C14.4824 13.3184 14.5537 12.9502 14.6963 12.6396C14.8408 12.3271 15.0479 12.0889 15.3174 11.9248C15.5889 11.7588 15.9062 11.6758 16.2695 11.6758C16.7754 11.6758 17.1709 11.7969 17.4561 12.0391C17.7412 12.2793 17.9102 12.6299 17.9629 13.0908H17.1074C17.0684 12.8467 16.9814 12.668 16.8467 12.5547C16.7139 12.4414 16.5303 12.3848 16.2959 12.3848C15.9971 12.3848 15.7695 12.4971 15.6133 12.7217C15.457 12.9463 15.3779 13.2803 15.376 13.7236V13.9961C15.376 14.4434 15.4609 14.7812 15.6309 15.0098C15.8008 15.2383 16.0498 15.3525 16.3779 15.3525C16.708 15.3525 16.9434 15.2822 17.084 15.1416V14.4062H16.2842V13.7588H17.9629V15.4609Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_968_65039"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_968_65039" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_968_65039"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_968_65039"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_968_65039"
        x1="1.06061"
        y1="9.00001"
        x2="28.4974"
        y2="8.84689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9900" />
        <stop offset="1" stop-color="#FFD206" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_968_65039"
        x1="12.5"
        y1="1"
        x2="20"
        y2="8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8C81E" />
        <stop offset="1" stop-color="#FFDE33" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
